
'use strict';

import Swiper, {Navigation, Pagination, Scrollbar } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar]);

// Function to check and update the swiper's state
function checkSwiperStatus(swiper) {
    var swiperContainerWidth = swiper.el.offsetWidth;
    var slideWidth = swiper.slides[0].offsetWidth + swiper.params.spaceBetween;
    var visibleSlides = Math.floor(swiperContainerWidth / slideWidth);
    var totalSlides = swiper.slides.length;
    if (totalSlides <= visibleSlides) {
        swiper.el.classList.add('is-no-swiping');
    } else {
        swiper.el.classList.remove('is-no-swiping');
    }
}

/**
 * @function
 * @description Slider used in the Content Slider Component for Page designer
 */
var swiperSlider = new Swiper('.js-slider', {
  spaceBetween: 16,
  loop: false,
  slidesPerView: 'auto',
  loop: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: true
  },
  breakpoints: {
    320: {
      spaceBetween: 16,
      slidesPerGroup: 2,
    },
    1023: {
      slidesPerGroup: 3,
    },
    1280: {
      slidesPerGroup: 4,
    },
  },
  on: {
    init: function () {
        checkSwiperStatus(this);
    },
    resize: function () {
        checkSwiperStatus(this);
    }
  }
});

/**
 * @function
 * @description Slider used in the Hero Slider Component for Page designer
 */
var swiperSlider = new Swiper('.js-hero-slider', {
  spaceBetween: 16,
  loop: false,
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: true
  },
  breakpoints: {
    768: {
      spaceBetween: 25,

    },
    1320: {
      slidesPerView: '3',
    }
  }
});
/**
 * @function
 * @description Slider used in the Step indicator Component for Page designer
 */
var swiperSlider = new Swiper('.js-stepindicator-slider', {
  spaceBetween: 16,
  loop: false,
  slidesPerView: 'auto',
  direction: 'vertical',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: true
  },
  breakpoints: {
    768: {
      spaceBetween: 25,

    },
    1320: {
      slidesPerView: '3',
    }
  }
});

