'use strict';

var $ = require('jquery');
// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = $;

var debounce = require('lodash.debounce');
var spinner = require('./project/components/spinner');
var consentTracking = require('./project/components/consentTracking');
var swiper = require('@baseJs/project/components/swiper');
var heroSlider = require('@baseJs/project/components/heroSlider');
var accordions = require('./project/components/accordions');
var gallery = require('./project/components/gallery');
var tabs = require('./project/components/tabs');
var countrySelector = require('./project/components/countrySelector');
var cart = require('./cart');
var pageHeader = require('./project/components/pageHeader');
var fittingbox = require('./project/components/fittingbox');
var navigation = require('./project/components/navigation');
var subscribe = require('./project/components/subscribe');
var tooltip = require('./project/components/tooltip');
var tryInStore = require('./project/components/tryInStore');
var loginVal = require('./project/components/loginValidation');
var formVal = require('./project/components/formValidation');
var appSlider = require('@baseJs/project/components/appointmentSlider');
var modal = require('./project/components/modal');
var onlineContactForm = require('./project/components/onlineContactForm');
var contactForm = require('@baseJs/project/components/contactForm');
var lazyLoad = require('./project/components/lazyLoad');
var newsletter = require('./project/account/newsletter');
var onlineChat = require('@baseJs/project/components/onlineChat');
var calendar = require('./project/components/calendar');
var wishlist = require('@baseJs/project/components/wishlist');
var tryInStoreSticky = require('@baseJs/project/components/tryInStoreSticky');
var inputClear = require('@baseJs/components/inputClear');
var recommendationSlider = require('@baseJs/recommendationSlider');
var processInclude = require('sfra/util');

$(document).ready(function () {
    processInclude(spinner);
    processInclude(consentTracking);
    processInclude(heroSlider);
    processInclude(accordions);
    processInclude(gallery);
    processInclude(countrySelector);
    processInclude(cart);
    processInclude(pageHeader);
    processInclude(fittingbox);
    processInclude(tabs);
    processInclude(navigation);
    processInclude(subscribe);
    processInclude(tooltip);
    processInclude(tryInStore);
    processInclude(loginVal);
    processInclude(appSlider);
    processInclude(modal);
    processInclude(onlineContactForm);
    processInclude(contactForm);
    processInclude(formVal);
    processInclude(lazyLoad);
    processInclude(newsletter);
    processInclude(onlineChat);
    processInclude(calendar);
    processInclude(wishlist);
    processInclude(tryInStoreSticky);
    processInclude(inputClear);
    processInclude(recommendationSlider);
});

//  Window resize with Debounce to improve performance
$(window).on('resize', debounce(() => {
    /**
     * @function
     * @description Change the Menu Position if the menu has more then 6 links
     */
    function changeOrderMenu() {
        var windowWidth = $(window).width();
        var navList = $('[data-js="nav-list"]');

        if (windowWidth > 1025 && windowWidth < 1249) {
            if (navList.children().length >= 6) {
                $('header').addClass('change-order');
                $('body').addClass('change-order');
            }
        } else {
            $('header').removeClass('change-order');
            $('body').removeClass('change-order');
        }
    }

    changeOrderMenu();
}, 600));
